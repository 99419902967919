import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 30 min. `}<meta itemProp="cookTime" content="PT30M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Whole grain thin spaghetti or angel hair, 1 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 6 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Red pepper flakes, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Large shrimp, 1 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 3 cloves</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Lemon, 1/2</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Capers, 2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parmesan, 1/2 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Black pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Baby spinach, 4 oz.</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Medium pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Large pan</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Zester</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Peel, devein, thaw, and dry shrimp</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate parmesan</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop garlic</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Bring medium pot of water to boil. Cook pasta until 1 minute short of al dente. Set aside 2 cups of pasta water and drain pasta.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`In large pan, melt butter and add red pepper flakes. Cook until butter is slightly brown.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add shrimp and garlic.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Zest lemon into pan and squeeze in lemon juice. Cook until shrimp is slightly pink.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Stir in pasta water until it looks saucy.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Stir in pasta, capers, black pepper, and parmesan.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Stir in baby spinach until wilted.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`If necessary, add more pasta water until desired texture.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      